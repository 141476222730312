import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DropDownField from "../../form/DropDownField";
import { useEffect, useRef, useState } from "react";
import HTTP from "../../../config/axios";
import { fieldRequired, min, lengthBetween, emailPattern, phoneNumberPattern, checkPassword, validateRoundedNumber, validateOnlySpaces, minLength, maxLength } from '../../../config/validations/rules';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck, faHeart } from "@fortawesome/free-solid-svg-icons";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { encryptLocalStorage } from "../../../config/utils/storage";
import InputTextField from "../../form/InputTextField";
import Stepper from "../../common/FormStepper";
import DateTimePicker from "../../form/DateTimePicker";
import { getFormatyyyymmdd } from "../../../config/utils/dateFunctions";
import Breadcrumbs from "../../common/Breadcrumbs";
import { formatKm } from "../../../config/utils/helper";
import SimpleToast from '../../common/Toast';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleSpinner from "../../common/Spinner";

const SellCar = () => {

    interface IMake {
        id: number;
        name: string;
        keyName: string;
    }

    interface responseType {
        success: boolean;
        error: any;
        data: {
            price_range:string;
            err?: string;
            status: number,
            error: string,
            message: string,
            type: string
            code:number,
        };
    }

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [makeArray, setMakeArray] = useState<IMake[]>([]);
    const [modelArray, setModelArray] = useState<IMake[]>([]);
    const [variantArray, setVariantArray] = useState<IMake[]>([]);
    const [yearArray, setYearArray] = useState<number[]>([]);
    const [makeData, setMakeData] = useState("");
    const [modelData, setModelData] = useState("");
    const [variantData, setVariantData] = useState("");
    const [yearData, setYearData] = useState("");
    const [kmData,setKmData] = useState("")
    const [vehiclePrice, setVehiclePrice] = useState("")
    const [homeEvaluation, setHomeEvaluation] = useState(false);
    const [storeEvaluation, setStoreEvaluation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [locationArray, setLocationArray] = useState<IMake[]>([]);
    const [outletArray, setOutletArray] = useState([])
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [vehicleDetails, setVehicleDetails] = useState<any>({})
    const [userFormData, setUserFormData] = useState<any>({})
    const stepperLabels = ['01', '02', '03', '04']
    const [activeStep, setActiveStep] = useState(1)
    const [currentForm, setCurrentForm] = useState(1)
    const [isDisabled, setIsDisabled] = useState(false)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const { register, handleSubmit, formState: { errors }, setError, clearErrors, control, getValues, reset, watch, resetField, setValue } = useForm();
    const [isMobile, setIsMobile] = useState(false);
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const sellRef = useRef<HTMLDivElement>(null);

    const kmOptions = [
        { name: "<10K", id: 1, keyName: 1, minValue: 10000 },
        { name: ">10K-30K", id: 2, keyName: 1, minValue: 30000 },
        { name: ">30K-50K", id: 3, keyName: 2, minValue: 50000 },
        { name: ">50K-70K", id: 4, keyName: 4, minValue: 70000 },
        { name: ">70K-90K", id: 5, keyName: 5, minValue: 90000 },
        { name: ">90K", id: 6, keyName: 6, minValue: 100000 }
    ];

    const noOfOwners = [
        { name: "1", id: 1, keyName: 1 },
        { name: "2", id: 2, keyName: 2},
        { name: "3", id: 3, keyName: 3 },
        { name: "4 above", id: 4, keyName: 4 },

    ]

    const locations = [
        { id: 1, name: 'Alappuzha', keyname: 'Alappuzha' ,outletId:19 },
        { id: 2, name: 'Ernakulam', keyname: 'Ernakulam', outletId: 1 },
        { id: 3, name: 'Idukki', keyname: 'Idukki', outletId: 13 },
        { id: 4, name: 'Kannur', keyname: 'Kannur', outletId: 4 },
        { id: 5, name: 'Kasaragod', keyname: 'Kasaragod', outletId: 4 },
        { id: 6, name: 'Kollam', keyname: 'Kollam', outletId: 24 },
        { id: 7, name: 'Kottayam', keyname: 'Kottayam', outletId: 18 },
        { id: 8, name: 'Kozhikode', keyname: 'Kozhikode', outletId: 6 },
        { id: 9, name: 'Malappuram', keyname: 'Malappuram', outletId: 6 },
        { id: 10, name: 'Palakkad', keyname: 'Palakkad', outletId: 9 },
        { id: 11, name: 'Pathanamthitta', keyname: 'Pathanamthitta', outletId: 21 },
        { id: 12, name: 'Thiruvananthapuram', keyname: 'Thiruvananthapuram', outletId: 22 },
        { id: 13, name: 'Thrissur', keyname: 'Thrissur', outletId: 8 },
        { id: 14, name: 'Wayanad', keyname: 'Wayanad', outletId: 6},
        { id: 15, name: 'Chennai', keyname: 'Chennai', outletId: 27}
    ];


    const removeSquareBrackets = (str:any)=>{
        return str.replace(/\[.*?\]/g, '').trim(); // Removes everything inside brackets
    }

    const getMaxPrice =(range:string)=>{
        let price = range.split(' - ')
        const maxPrice = parseInt(price[1].replace(/,/g, ''))

        return maxPrice
    }

    useEffect(() => {
        try {
            const getOutlet = async () => {
                const locationData = await HTTP.get(`/api/location/fetch-all`, { params: { status: 1 } });
                const { data: { status, data, msg, error } } = locationData

                if (status == 1) {
                    setLocationArray(data)
                } else {
                    setLocationArray([])
                }
            }
            getOutlet()
        }
        catch (err) {

        }
    }, [])

    //to get the location id 
    const getLocationIdByName = (locationArray: any, locationName: string): number | null => {
        const location = locationArray.find((loc:any) => loc.name.toLowerCase() === locationName.toLowerCase());
        return location ? location.id : null; // Return the id if location is found, else return null
    };

  

    useEffect(()=>{
        console.log("location", locationArray)
    }, [locationArray])



    const storedData = encryptLocalStorage.getItem("SellCarData");

    useEffect(() => {
        const hash = currentLocation.hash;
        if (hash === '#sell-form' && sellRef.current) {
          sellRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [currentLocation]);
      
    useEffect(() => {
        const getCarMakeData = async () => {
            const response = await HTTP.get(`/api/make/all`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setMakeArray(data)
            } else {
                setMakeArray([])
            }
        }
        getCarMakeData()
        
    }, []);

    useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 565); 
    };

    handleResize();


    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const firstYear = currentYear - 15;
        const yearRange = [];
        for (let year = currentYear; year >= firstYear; year--) {
            yearRange.push(year);
        }
        setYearArray(yearRange)
    }, [])

    useEffect(() => {
      
        const fetchUser = async () => {
            const response = await HTTP.get(`/api/fetch-profile`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setValue('firstName', data.firstName)
                setValue('lastName', data.lastName)
                setValue('ownerMobile', data.phone)
                setValue('ownerEmail', data.email)
            }
        }
        if (currentForm == 2) {
            fetchUser()
            
        }
     
    }, [currentForm])

    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        if (token) {
            setIsLoggedIn(true);
        }
    }, [])

    const make = watch('make')
    useEffect(() => {
        const handleMakeChange = async () => {
            const response = await HTTP.get(`/api/model/get-by-make-id/${make}`);
            const { data: { status, data, msg, error } } = response;
            if (status == 1) {

                setModelArray(data)
                setVariantArray([]);
                resetField('model', {
                    keepDirty: false,
                    keepError: false,
                    keepTouched: false,
                    defaultValue: null // when provided with defaultVlaue update both input value and internal defaultValues
                })
                resetField('variant', {
                    keepDirty: false,
                    keepError: false,
                    keepTouched: false,
                    defaultValue: null // when provided with defaultVlaue update both input value and internal defaultValues
                })
            } else {
                setModelArray([])
                setVariantArray([]);
            }

        };
        make && handleMakeChange()
    }, [make])

    const model = watch('model')
    useEffect(() => {
        const handleModelChange = async () => {
            try {
                const response = await HTTP.get(`/api/vehicle/get-variant-by-model-id/${model}`);
                const { data: { status, data, msg, error } } = response;
                if (status == 1) {
                    setVariantArray(data)
                } else {
                    setVariantArray([])
                }
            }
            catch (error) {
                console.log("Error in model fetch api", error)
            }

        };
        model && handleModelChange()

    }, [model])






    //Handling the first form (Vehicle Details Form)
    const vehicleDetailsForm = (data: any) => {
        setVehicleDetails(data)
        setCurrentForm(2)
        const year = getValues('year')
        const make = getValueByKey(makeArray, getValues('make'))
        const model = getValueByKey(modelArray, getValues('model'))
        const variant = getValueByKey(variantArray, getValues('variant'))
        setYearData(year)
        setMakeData(make)
        setModelData(model)
        setVariantData(variant)
        encryptLocalStorage.removeItem("SellCarData");
    }

    const signUpForm = async (data: any) => {
        try {

        setIsLoading(true);

        let cleanedMakeData = removeSquareBrackets(makeData).toUpperCase();
        let cleanedModelData = removeSquareBrackets(modelData).toUpperCase();
        let cleanedVariantData = removeSquareBrackets(variantData).toUpperCase();
        const location = getValueByKey(locations, data.locations)
        const outletId = getOutletIdByKey(locations, data.locations)
        const locationId = getLocationIdByName(locationArray, location);
        const kmDriven = getValueByKey(kmOptions, data.kmDriven)
        setKmData(kmDriven)

        let formData = {
            brand_id: cleanedMakeData,
            sub_model_id: cleanedModelData,
            variant_id: cleanedVariantData,
            //   fuel_id: "1", // example fuel type
            km_id: data.kmDriven, // example KM driven
            owner_id: data.owners, // example owner type
            year_id: yearData,
            name: `${data.firstName} ${data.lastName}`,
            phone: data.ownerMobile,
            location: location

        }
        
        const response: any = await HTTP.post(`https://www.cardeals365.com/api/form-submit`, formData);
        
            if (response.data?.code === 200) {
                const priceRange = response.data.data?.price_range;
                if (priceRange) {
                    setVehiclePrice(priceRange);
                } else {
                }
            } else {
                console.error("Error: Failed to submit the form. Code:", response.data?.code);
            }
            const updatedData = {
                ...data,
                locationId,
                kmData,
                outletId
            };
        
            // Proceed to the next form step
            setCurrentForm(3);
            setUserFormData(updatedData);
            
        } catch (err) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }


    const onSubmit = async (data: any) => {
        try {
            setIsDisabled(true)
            const { make, model, variant, year, km, owners, firstName, lastName, ownerMobile, ownerEmail, password, outletId, timing, address, landmark, date } = data;

            const kmMinValue = getKmMinValue(data.kmDriven);

            // Perform form submission logic here

            if (homeEvaluation || storeEvaluation) {
                const vehicle = vehicleDetails

                const vehicleEvaluateDetails: any = { evaluationType: (homeEvaluation) ? 1 : 2 }
                if (homeEvaluation) {
                    vehicleEvaluateDetails['address'] = address;
                    vehicleEvaluateDetails['landmark'] = landmark;
                }
                if (storeEvaluation) {
                    vehicleDetails.outletId = outletId
                    vehicleEvaluateDetails['outletId'] = outletId;
                    vehicleEvaluateDetails['evaluationTime'] = timing;
                    vehicleEvaluateDetails['evaluationDate'] = date;
                }
                const user = {
                    firstName: userFormData.firstName,
                    lastName: userFormData.lastName,
                    email: userFormData.ownerEmail,
                    password: userFormData.password,
                    phone: userFormData.ownerMobile,
                    locationId:userFormData.locationId,
                    outletId:userFormData.outletId,
                    siteToken: executeRecaptcha ? await executeRecaptcha('signup') : ''
                }

                const vehicleMaxPrice = vehiclePrice?getMaxPrice(vehiclePrice):0

                let sellCarData = { vehicle, user, vehicleEvaluateDetails, vehicleMaxPrice, kmDrivenMinValue: kmMinValue }
                const response: responseType = await HTTP.post(`/api/vehicle/sell-car`, sellCarData);

                if (response.data.status === 1) {
                    setCurrentForm(6)
                    setHomeEvaluation(false);
                    setStoreEvaluation(false);
                    reset();
                } else if(response.data.type) {
                    setError(response.data.type ? response.data.type : "serverError", {
                        type: "manual", message: response.data.message,
                    });
                } else {
                    const msg = response.data.message
                    setToast({ open: true, header: 'Failed', message: (msg) ? msg : 'Something went wrong', variant: 'danger' })
                }
            }
        }
        catch (error:any) {
            const msg = error.response.data.message
            setToast({ open: true, header: 'Failed', message: (msg) ? msg : 'Something went wrong', variant: 'danger' })
        }
        finally {
            setIsDisabled(false)
        }
    };



    const formattedOwner = (i: number) => {
        let j = i % 10
        let k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    const getValueByKey = (data: Array<any>, element: string) => {
        let foundItem = data.find((item: any) => item.id == element);
        return foundItem.name;
    };
    const getOutletIdByKey = (data: Array<any>, element: string) => {
        let foundItem = data.find((item: any) => item.id == element);
        return foundItem.outletId;
    };
    const getKmMinValue = (kmDrivenId: any) => {
        const selectedOption = kmOptions.find(option => option.id === Number(kmDrivenId));  // Ensure both are numbers
        return selectedOption ? selectedOption.minValue : null;
    };



    useEffect(() => {
        switch (currentForm) {
            case 1: setActiveStep(1); break;
            case 2: setActiveStep(2); break;
            case 3: setActiveStep(3); break
            case 4:
            case 5:
            case 6: setActiveStep(4); break;
        }
    }, [currentForm])


    const timeSlots = [];
    // let currentHour = new Date().getHours() + 1
    let currentHour = 9
    for (let hour = currentHour; hour < 20; hour++) {
        if (hour === 11) {
            timeSlots.push(`${hour} am - ${hour + 1} pm`);
        } else if (hour < 11) {
            timeSlots.push(`${hour} am - ${hour + 1} am`);
        } else {
            let thisHour = (hour - 12) === 0 ? '12' : (hour - 12)
            timeSlots.push(`${thisHour} pm - ${hour + 1 - 12} pm`);
        }
    }

    // FEtch all location list
    // useEffect(() => {
    //     try {
    //         const getOutlet = async () => {
    //             const locationData = await HTTP.get(`/api/location/fetch-all`, { params: { status: 1 } });
    //             const { data: { status, data, msg, error } } = locationData

    //             if (status == 1) {
    //                 setLocationArray(data)
    //             } else {
    //                 setLocationArray([])
    //             }
    //         }
    //         getOutlet()
    //     }
    //     catch (err) {

    //     }
    // }, [])


    useEffect(() => {
        try {
            const getOutlet = async () => {
                const locationData = await HTTP.get(`/api/location/outlet/fetch-all`, { params: { status: 1 } });
                const { data: { status, data, msg, error } } = locationData

                if (status == 1) {
                    setOutletArray(data.rows)
                } else {
                    setOutletArray([])
                }
            }
            getOutlet()
        }
        catch (err) {

        }
    }, [])

    const breadCrumbList = [
        { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
        { title: "Sell Car", path: "/sell-your-car", active: true, linkClass: "text-primary fw-semibold" }
    ]

    const handleSignIn = () => {
        const formValues = getValues();
        encryptLocalStorage.setItem("SellCarData", JSON.stringify(formValues));
        encryptLocalStorage.setItem("previousLoggedoutLocation",currentLocation.pathname+"#sell-form")
        navigate("/sign-in")
      }
    
      useEffect(() => {
        if (storedData) {
             reset({
                year:storedData.year,
                km:storedData.km,
                owners:storedData.owners
            });
        }
      }, []);

      useEffect(() => {
        
        if(makeArray.length && storedData) setValue("make",storedData.make)
      },[makeArray])

      useEffect(() => {
        if(modelArray.length && storedData) setValue("model",storedData.model)
      },[modelArray])

      useEffect(() => {
        if(variantArray.length && storedData) setValue("variant",storedData.variant)
      },[variantArray])

    return (
        <div className="App">
            <Container ref={sellRef}>
                <Row className="pb-5 mobile-pt-5">
                    <Breadcrumbs breadcrumbData={breadCrumbList} />
                    <Col md={12}>
                        <div className="text-center pb-4">
                            <h2 className="text-primary head-bold">LET'S GET STARTED</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis
                                massa aliquam lectus et nulla sit quis. Dignissim urna aliquet
                                vestibulum sed urna, quis augue risus rhoncus.
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="text-center pt-4">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/find_car.svg`} width={"70%"} />
                        </div>
                    </Col>
                    <Col md={6}>
                        {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
                        <Row>
                            <Stepper steps={3} active={activeStep} labels={stepperLabels} />
                            {currentForm == 1 && (
                                <>
                                    <Form onSubmit={handleSubmit(vehicleDetailsForm)}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-2">
                                                    <DropDownField label="Make" name="make" options={makeArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose make" />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-2">
                                                    <DropDownField label="Model" name="model" options={modelArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose model" />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-2">
                                                    <DropDownField label="Variant" name="variant" options={variantArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose variant" />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-2">
                                                    <DropDownField label="Year" name="year" options={yearArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose year" />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-2">
                                                    <DropDownField name="kmDriven" label="KM Driven" options={kmOptions}
                                                        rule={{ required: fieldRequired }}
                                                        control={control} placeholder="Choose KM's" isValueKeyname={true} />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-2">
                                                    <DropDownField name="owners" label="No of owners" options={noOfOwners}
                                                        rule={{ required: fieldRequired }}
                                                        control={control} placeholder="No of owners" isValueKeyname={true} />
                                                </div>
                                            </Col>

                                            <Col md={2} className="pt-2">
                                            {!isLoggedIn ?
                                                (
                                                <Button variant="primary" onClick={handleSignIn}>
                                                    Next
                                                </Button>
                                                ) : (
                                                <Button variant="primary" type="submit" >
                                                    Next
                                                </Button>
                                             )}
                                            </Col >
                                        </Row>
                                    </Form>
                                </>
                            )}
                            {currentForm == 2 && (
                                <>

                                    <Form onSubmit={handleSubmit(signUpForm)}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <InputTextField label="First name" name="firstName" control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Enter first name" disabled={isLoggedIn} />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <InputTextField label="Last name" name="lastName" control={control} clearErrors={clearErrors} placeholder="Enter last name" disabled={isLoggedIn} />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <InputTextField label="Mobile number" name="ownerMobile" control={control} rule={{ required: fieldRequired, pattern: phoneNumberPattern }}   placeholder="Enter mobile no" disabled={isLoggedIn} />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <InputTextField label="Email" name="ownerEmail" type="email" control={control} rule={{  pattern: emailPattern }}   placeholder="Enter email" disabled={isLoggedIn} />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-2">
                                                    <DropDownField label="Locations" name="locations" options={locations} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose Location" />
                                                </div>
                                            </Col>

                                            {!isLoggedIn &&
                                                <>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <InputTextField label="Password" name="password" type="password" control={control} rule={{ required: fieldRequired, validate: (value: string) => lengthBetween(value, 6, 12) }} clearErrors={clearErrors} placeholder="Enter password" />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <InputTextField label="Confirm password" name="cPassword" type="password" control={control} rule={{ required: fieldRequired, validate: (value: string) => checkPassword(value, getValues('password')) }} clearErrors={clearErrors} placeholder="Re-enter password" />
                                                        </div>
                                                    </Col>
                                                </>
                                            }
                                            <div className="d-flex align-items-center pt-3" >
                                                <FontAwesomeIcon icon={faArrowLeft} className=" me-3 fs-4 text-primary " style={{ cursor: 'pointer' }} onClick={() => setCurrentForm(1)} />
                                                <h3 className="text-primary mb-0" style={{ cursor: 'pointer' }} onClick={() => setCurrentForm(1)}>Update details? need to go back?</h3>
                                            </div>
                                            <Col md={4} className="pt-3">
                                                <Button variant="primary" type="submit">
                                                    Next
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            )}
                            {
                                currentForm == 3 && (
                                    <>
                                        <Col md={12}>
                                            <div className="">
                                                <h2 className="is-testimonials mb-0 text-dark">{`${yearData} ${makeData} ${modelData} ${variantData}`} </h2>
                                            </div>
                                            <h3 className="text-dark">{kmData} KM Driven, {formattedOwner(vehicleDetails.owners)} owner</h3>
                                            <p>Vehicle valuation and pricing is done based on your inputs.</p>
                                            <h2 className="text-primary">{vehiclePrice ? `₹${vehiclePrice}` : "Oops! The price isn't available right now."}</h2>
                                            <div className="d-flex align-items-center pt-3" >
                                                <FontAwesomeIcon icon={faArrowLeft} className=" me-3 fs-4 text-primary " style={{ cursor: 'pointer' }} onClick={() => setCurrentForm(2)} />
                                                <h3 className="text-primary mb-0" style={{ cursor: 'pointer' }} onClick={() => setCurrentForm(2)}>Update details? need to go back?</h3>
                                            </div>
                                        </Col>
                                        <Col md={4} className="pt-5">
                                            <Button variant="primary" type="submit" onClick={() => { setCurrentForm(4); setHomeEvaluation(true) }}>
                                                Evaluate at home
                                            </Button>
                                        </Col>
                                      <Col md={4} className={`${isMobile ? 'pt-4' : 'pt-5'}`}>
                                            <Button variant="outline-primary" type="submit" onClick={() => { setCurrentForm(5); setStoreEvaluation(true) }}>
                                                Evaluate at store
                                            </Button>
                                        </Col>
                                    </>
                                )
                            }
                            {
                                currentForm == 4 && (
                                    <>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <InputTextField label="Address" name="address" control={control} rule={{ required: fieldRequired  , validate: validateOnlySpaces}} placeholder="Enter address" 
                                                        
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <InputTextField label="Landmark" name="landmark" control={control} rule={{ required: fieldRequired, validate: validateOnlySpaces }} placeholder="Enter landmark" />
                                                    </div>
                                                </Col>
                                                <div className="d-flex align-items-center pt-3" >
                                                    <FontAwesomeIcon icon={faArrowLeft} className=" me-3 fs-4 text-primary " style={{ cursor: 'pointer' }} onClick={() => setCurrentForm(3)} />
                                                    <h3 className="text-primary mb-0" style={{ cursor: 'pointer' }} onClick={() => setCurrentForm(3)}>Update details? need to go back?</h3>
                                                </div>
                                            </Row>
                                            <Col md={4} className="pt-3">
                                                <Button variant="primary" type="submit" disabled={isDisabled}>
                                                    Evaluate at home
                                                </Button>
                                            </Col>
                                        </Form>
                                    </>
                                )
                            }
                            {
                                currentForm == 5 && (
                                    <>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-3">

                                                        <DropDownField name="outletId" label="Outlet"
                                                            options={outletArray} placeholder=" Choose outlet "
                                                            control={control} rule={{ required: fieldRequired }} />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <DateTimePicker name="date" label="Visit Date" min={getFormatyyyymmdd(new Date())}
                                                            type="date" control={control} rule={{ required: fieldRequired }} />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <DropDownField label="Timings"
                                                            name="timing" options={timeSlots}
                                                            placeholder="Choose timings" control={control} rule={{ required: fieldRequired }} />
                                                    </div>
                                                </Col>
                                                <div className="d-flex align-items-center pt-3" >
                                                    <FontAwesomeIcon icon={faArrowLeft} className=" me-3 fs-4 text-primary " style={{ cursor: 'pointer' }} onClick={() => setCurrentForm(3)} />
                                                    <h3 className="text-primary mb-0" style={{ cursor: 'pointer' }} onClick={() => setCurrentForm(3)}>Update details? need to go back?</h3>
                                                </div>
                                                <Col md={4} className="pt-3">
                                                    <Button variant="primary" type="submit" disabled={isDisabled} >
                                                        Evaluate at store
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>

                                    </>
                                )
                            }

                            {currentForm == 6 && (
                                <Row>
                                    <Col md={{ span: 12 }}>
                                        <p className="text-success fw-bold pt-4"><FontAwesomeIcon icon={faCheck} className="me-3" />Request submitted successfully </p>
                                    </Col>
                                    <Col md={{ span: 12 }} className="pt-4">
                                        <Button variant="primary" onClick={() => setCurrentForm(1)}>
                                            Add another car
                                        </Button>
                                    </Col>
                                </Row>
                            )}

                        </Row>

                    </Col>
                </Row>
            </Container>
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </div >
    );
};

const SellCarForm = () => {

    const [siteKey, setSiteKey] = useState('')
    useEffect(() => {
        const getSiteKey = async () => {
            try {
                const response = await HTTP.get('/api/fetch-site-key')
                const { status, siteKey } = response.data
                if (status && siteKey) {
                    setSiteKey(siteKey)
                }
            }
            catch (err) {
                console.log('Check Error from get site key', err)
            }
        }
        getSiteKey()
    }, [])


    return siteKey ? (<GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <SellCar />
    </GoogleReCaptchaProvider>) : <SellCar />
}

export default SellCarForm;
