import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import InputTextField from '../../../components/form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleSpinner from '../../../components/common/Spinner';
import SimpleToast from '../../../components/common/Toast';
import MultiRadio from '../../../components/form/MultiRadio';


const BookingConfig: React.FC = () => {
    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch } = useForm<Record<string, any>>();
    const [isLoading, setIsLoading] = useState(false);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [configStatus,setConfigStatus] = useState()
    const configValueStatus = watch("bookingConfig");


    const bookingOptions = [
         { label: 'Amount', value: '0' },
        { label: 'Percentage', value: '1' }
       
    ]

    useEffect(()=>{
        //Fetch the vehicle AMount
        const getBookingAmount = async () => {
            const response = await HTTP.get(`/api/booking/booking-amount`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
               
                reset({ 'bookingAmount': data.value, 'bookingConfig': data.status })
           
            }
        }

        getBookingAmount()
    },[])

    useEffect(() => {
        setConfigStatus(configValueStatus);
    }, [configValueStatus]);

    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true);
            const response = await HTTP.post(`/api/admin/booking/addEditBookingAmount`, submitData)
            setToast({ open: true, header: response.data.status?'Success':'failed', 
                message: response.data.status?response.data.message:'Something went wrong', variant: response.data.status?'success': 'danger'})
            
        }
        catch (err) {
            
        }
        finally {
            setIsLoading(false);    
        }
    }


    return (
        <>
            <div id="wrapper">
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <div className="container-fluid">
                            <Row className='mb-1'>
                                <div className="d-flex">
                                    <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Booking Configuration</h2></div>
                                </div>
                            </Row>
                            <Form onSubmit={handleSubmit(onSubmit)} className='p-2'>
                                <div className="d-flex flex-row align-items-center">
                                    
                                        <Form.Label htmlFor={`childId-id`} className="text-dark fw-semibold fs-6 me-3 mb-0">
                                            Booking Amount : <span className="text-danger fw-bold">*</span>
                                        </Form.Label>
                                    
                                        
                                        <InputTextField name="bookingAmount" label=""
                                        placeholder={(configStatus == 1) ? "Enter Percentage" : "Enter Amount"} clearErrors={clearErrors} classList='w-25'
                                            control={control} rule={{ required: fieldRequired }} type='number'/>
                                        
                                        <Button variant="primary" type="submit" className='ms-3 py-2 px-4'>
                                            Save
                                        </Button>
                                </div>
                                <div className="p-2 mt-2 d-flex " style={{ cursor: "pointer" }}>
                                    <MultiRadio name="bookingConfig" control={control}
                                        options={bookingOptions} classList="mb-3 pt-0 px-2" selectedValue={watch("bookingConfig")} />
                                </div>

                            </Form>
                            
                        </div>
                    </div>
                </div>
            </div>
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
};

export default BookingConfig;