import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { PaginationChangeRowsPerPage } from 'react-data-table-component/dist/DataTable/types';

interface ReportDataProps {
    reportData: any
    handlePageChange: PaginationChangeRowsPerPage | undefined
    handlePerRowsChange: PaginationChangeRowsPerPage | undefined
}

const EnquiryReportDataTable: React.FC<ReportDataProps> = ({ reportData, handlePageChange, handlePerRowsChange }): any => {

    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: <div>Serial No.</div>,
            wrap: true,
            selector: (row: any, index: number) => index + 1,
            sortable: false,
            width: "10%",  // Adjust width as needed
        },
        {
            name: <div>Model Name</div>,
            wrap: true,
            sortable: true,
            keyName: 'name',
            width: "17%",
            selector: (row: { name: string }) => row.name,
        },
        {
            name: <div>Count</div>,
            wrap: true,
            sortable: true,
            width: "17%",
            selector: (row: { userCount: string; }) => row.userCount,
         
        },
        {
            name: <div>Enquiry date</div>,
            wrap: true,
            sortable: true,
            width: "17%",
            selector: (row: { enquiryDate: string; }) => row.enquiryDate,

        },

    ];

    useEffect(()=>{
        console.log(reportData.rows)
    }, [reportData])

    return (<>
        <br></br>
        <DataTable
            columns={columns}
            data={reportData.rows}
            striped={true}
            pagination
            paginationServer
            paginationTotalRows={reportData.count}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

    </>)

}
export default EnquiryReportDataTable