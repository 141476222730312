import { Col, Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from "react";
import { ComparisonContext, ComparisonContextProvider } from "../../../../config/context/comparisonContext"
import SimpleToast from "../../../common/Toast";

import CompareBrakeMob from "./Brake";
import ComparePerformanceMob from "./Performance";
import CompareFuelMob from "./Fuel";
import CompareImageMob from "./CarImage";
import CompareSuspensionMob from "./Suspension";
import CompareSteeringMob from "./Steering";
import CompareTransmissionMob from "./Transmission";
import StandardFeaturesMob from "./StandardFeatures";
import CompareOverviewMob from "./Overview";
import CompareEngineMob from "./Engine";


const ComparisonMob = () => {

  const location = useLocation()
  let toastValues = { open: false, header: '', message: '', variant: '' }

  // if(location?.state?.isLimit >= 3){
  //   toastValues = { open: true, header: 'Failed', message: 'You can compare only 2 cars at a time. Change any car in list to add new car to compare', variant: 'danger',  }
  //   window.history.replaceState({}, '');
  // }

  if(location?.state?.aboveLimit){
    toastValues = { open: true, header: 'Failed', message: 'You can compare only 2 cars at a time. Change any car in list to add new car to compare', variant: 'danger',  }
    window.history.replaceState({}, '');
  }

  const [compareData, setCompareData] = useState<any>({})
  const [toast, setToast] = useState(toastValues)



  return (
    <ComparisonContextProvider>
      <Container>
        <h2 className="text-primary mt-120 mb-4 head-bold">CAR COMPARISON</h2>
          <CompareImageMob />
        <Col md={12} sm={12}>
          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            <Accordion.Item eventKey="0" className="mb-2">
              <Accordion.Header className="head">
                Car overview {compareData.first}
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareOverviewMob /> 
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className="mb-2">
              <Accordion.Header className="head ">
                Standard features
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <StandardFeaturesMob />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className="mb-2">
              <Accordion.Header className="head ">
                Engine
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareEngineMob /> 
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className="mb-2">
              <Accordion.Header className="head ">
                Transmission
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareTransmissionMob /> 
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4" className="mb-2">
              <Accordion.Header className="head ">
                Suspension system
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareSuspensionMob /> 
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5" className="mb-2">
              <Accordion.Header className="head ">
                Steering
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareSteeringMob /> 
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6" className="mb-2">
              <Accordion.Header className="head ">
                Brake System
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareBrakeMob /> 
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7" className="mb-2">
              <Accordion.Header className="head ">
                Performance
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <ComparePerformanceMob /> 
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8" className="mb-2">
              <Accordion.Header className="head ">
                Fuel
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareFuelMob /> 
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
          <div className="pb-5"></div>
        </Col>
      </Container>


      <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} delay={5000} />
    </ComparisonContextProvider>
  );
};

export default ComparisonMob;
