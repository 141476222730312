import { useEffect, useState } from "react";
import { Container, Col, Card, Row, Form, Button } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom"
import HTTP from "../../../config/axios";
import InputTextField from "../../form/InputTextField";
import InputTextAreaField from "../../form/InputTextAreaField";
import DropDownField from "../../form/DropDownField"
import { equalTo, fieldRequired, max, min, maxLength, phoneNumberPattern, checkRequired, pinCodePattern, validateOnlySpaces } from '../../../config/validations/rules';
import { useForm } from "react-hook-form";
import CheckBox from "../../form/Checkbox";
import { useNavigate } from "react-router-dom";
import SimpleToast from "../../common/Toast";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { redirectToPaymentGateway } from "../Payment/Worldline/requestForm";
import { generateOrderNo, priceFormat } from "../../../config/utils/helper";

interface PaymentResponse {
    paymentMethod?: {
      paymentTransaction?: {
        statusCode?: string;
      };
    };
  }

const CarCheckout = () => {

    const defaultState = { vehicleId: '', bookingId: '', proofUrl: '', panNumber: '', selectedLocation: '' };
    const { state } = useLocation();
    const { vehicleId, bookingId, proofUrl, panNumber, price, selectedLocation } = state || defaultState;
    const [vehicleDetails, setVehicleDetails] = useState<any>({ make: '', model: '', fuel: '', variant: '', colour: '', location: '', dealPrice:0, price:0 })
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])
    const [submitVisible, setSubmitVisible] = useState(false)
    const [bookingAmount, setBookingAmount] = useState<string>('5000')
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const { control, handleSubmit, watch, setValue, getValues } = useForm<Record<string, any>>({ defaultValues: { payOnline: 1 } })
    const navigate = useNavigate();

    const location = encryptLocalStorage.getItem("selectedLocation")
    if (!vehicleId || !bookingId) navigate(`/buy-used-car`)

    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        if (!token) {
            window.location.replace("/sign-in");
        }
    }, []);


    useEffect(() => {
        try {
            const getvehicleDetails = async () => {
                const response = await HTTP.get(`/api/vehicle/fetch-one/${vehicleId}`);
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setVehicleDetails(data)
                }
            }

            //Fetch the vehicle AMount
     
            getvehicleDetails()
        }
        catch (error) {
            console.log("Error in car details fetch API", error)
        }
    }, []);

    useEffect(()=>{
        const getBookingAmount = async () => {
            const response = await HTTP.get(`/api/booking/booking-amount`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                if (data.status == 1) {
                    const percentage = parseFloat(data.value);
                    if(vehicleDetails.dealPrice){
                        const bookingConfigAmount = vehicleDetails.dealPrice * (percentage / 100);
                        setBookingAmount(bookingConfigAmount.toString());
                    }
                    else{
                        const bookingConfigAmount = vehicleDetails.price * (percentage / 100);
                        setBookingAmount(bookingConfigAmount.toString());
                    }
                }
                else{
                    setBookingAmount(data.value)
                }
               
            }
        }

        getBookingAmount()

    }, [vehicleDetails])


    useEffect(() => {
        try {
            const getAllStates = async () => {
                const response = await HTTP.post(`/api/booking/states/fetch-all`);
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setStates(data)
                }
            }
            getAllStates()
        }
        catch (error) {
            console.log("Error in states fetch API", error)
        }
    }, []);

    const selectedStateId = watch('stateId')
    useEffect(() => {
        try {
            setValue('districtId', 'default'); setDistricts([])
            const getAllDistrict = async () => {
                const response = await HTTP.post(`/api/booking/districts/fetch-all/${selectedStateId}`);
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setDistricts(data)
                }
            }
            selectedStateId && getAllDistrict()
        }
        catch (error) {
            console.log("Error in states fetch API", error)
        }
    }, [selectedStateId]);


//   useEffect(() => {
//         const token = encryptLocalStorage.getItem("jwtToken");
//        if(token){`
//         try{
          
//             const fetchUser = async () => {
//             const response = await HTTP.get(`/api/fetch-profile`);
//             const { data: { status, data, msg, error } } = response
          
//             if(status==1){
//                setValue('firstName',data.firstName)
//                setValue('lastName',data.lastName)
//                setValue('phone', data.phone)
//                setValue('email', data.email)
//             }
//         }
//         fetchUser()
//        }
//         catch(error){
//            console.log("Error in states fetch API", error)
//        }      
//      }
//     }, [])
 


    const onSubmit = async (submitData: any) => {
        try {
            submitData.bookingId = bookingId
            submitData.bookingAmount = bookingAmount
            setSubmitVisible(true)
            const response = await HTTP.post("/api/booking/edit", submitData)
            const { data: { status, data, message, merchantRequest, mid, error, orderNo ,paymentFormData} } = response
            if (status == 1) {
                

                if (getValues('payOnline') == 1 && merchantRequest && mid) {
                  handlePayment(paymentFormData)
                } else {
                    navigate(`/car/order/${orderNo}`)
                }

            }
            else if (status == 0) {
                setToast({ open: true, header: 'Something went wrong', message: error.message, variant: 'danger' })
            }
        }
        catch (error) {
            setSubmitVisible(false)
            console.log("Error in booking submit API", error)
        }
    }


    const handleResponse = (res: PaymentResponse) => {
        if (
          res &&
          res.paymentMethod &&
          res.paymentMethod.paymentTransaction &&
          res.paymentMethod.paymentTransaction.statusCode
        ) {
          switch (res.paymentMethod.paymentTransaction.statusCode) {
            case '0300':
              console.log('Payment Successful');
              break;
            case '0398':
              console.log('Payment Initiated');
              break;
            default:
              console.log('Payment Failed');
              break;
          }
        }
      };
    
      const handlePayment = async (formData:any) => {

        try {
          const response = await HTTP.post('/api/payment-request', formData);
          const hashData = await response.data;
          const reqJson = {
            
            features: {
              enableAbortResponse: true,
              enableExpressPay: true,
              enableInstrumentDeRegistration: true,
              enableMerTxnDetails: true,
            },
            consumerData: {
              ...formData,
              token: hashData.data,
              responseHandler: handleResponse,
              merchantLogoUrl: 'https://www.paynimo.com/CompanyDocs/company-logo-vertical.png',
              customStyle: {
                PRIMARY_COLOR_CODE: '#45beaa',
                SECONDARY_COLOR_CODE: '#FFFFFF',
                BUTTON_COLOR_CODE_1: '#2d8c8c',
                BUTTON_COLOR_CODE_2: '#FFFFFF',
              },
       
            },
          };
    
          // @ts-ignore
          $.pnCheckout(reqJson);
          // @ts-ignore
          if (reqJson.features.enableNewWindowFlow) {
            // @ts-ignore
            pnCheckoutShared.openNewWindow();
          }
        } catch (error) {
          console.error('Error generating hash:', error);
        }
      };


    return vehicleId && bookingId &&
        (<><Container className="mt-120">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="my-5">
                    <div className="text-primary text-center pb-4">
                        <h2>Checkout</h2>
                    </div>
                    <Col md={6} className="border-end">
                        <h3 className="text-primary">Personal Information</h3>
                        <div className="mb-3">
                            <InputTextField name="firstName"
                                label="First name"
                                placeholder="Enter first name"
                                rule={{ required: fieldRequired , validate: validateOnlySpaces}}
                                control={control} />
                        </div>
                        <div className="mb-3">
                            <InputTextField name="lastName"
                                label="Last name"
                                placeholder="Enter last name"
                            rule={{ required: fieldRequired, validate: validateOnlySpaces }}
                                control={control} />
                        </div>
                        <div className="mb-3">
                            <InputTextField name="email"
                                label="Email address"
                                placeholder="Enter Email ID"
                                type="email"
                                rule={{ required: fieldRequired }}
                                control={control} />
                        </div>
                        <div className="mb-3">
                            <InputTextField name="phone" label="Phone number"
                                placeholder="Enter phone no"
                                type="number"
                                rule={{ required: fieldRequired, pattern: phoneNumberPattern, min: min(0) }}
                                control={control}
                                errText="Phone number must be 10 digits" />
                        </div>
                        <div className="mb-3">
                            <InputTextAreaField name="billingAddress"
                                label="Billing address"
                                placeholder="Enter billing address"
                                rows={3}
                                rule={{ required: fieldRequired, validate: validateOnlySpaces }}
                                control={control} />
                        </div>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <DropDownField name="stateId"
                                        label="Region / state"
                                        placeholder="Choose Region / state"
                                        options={states}
                                        rule={{ required: fieldRequired }}
                                        control={control} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <DropDownField name="districtId"
                                        label="City"
                                        placeholder="Choose City"
                                        options={districts}
                                        rule={{ required: fieldRequired }}
                                        control={control} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <InputTextField name="pincode"
                                        label="Pin code"
                                        placeholder="Enter pincode"
                                        type="number" min={0}
                                        rule={{ required: fieldRequired, pattern: pinCodePattern }}
                                        control={control}
                                        errText="Pin code must be 6 digits" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <h3 className="text-primary">Booking Details</h3>
                        <h3 className="text-dark">{vehicleDetails.make} {vehicleDetails.model}</h3>
                        <div>
                            <ul className="text-dark pt-3">
                                <li className="pb-4">  Fuel : <span className="text-secondary">{vehicleDetails.fuel}</span></li>
                                <li className="pb-4">Variant : <span className="text-secondary">{vehicleDetails.variant}</span></li>
                                <li className="pb-4"> Color : <span className="text-secondary">{vehicleDetails.colour}</span></li>
                                <li className="pb-4">PAN : <span className="text-secondary">{panNumber}</span> </li>
                                <li> Proof : <Link to={proofUrl} download={true} target="_blank" > Address Proof </Link></li>
                            </ul>
                        </div>
                        <h3 className="text-primary">Payment Amount</h3>
                        <div className="mb-3 text-dark">
                            {/* {price} */}
                            {priceFormat(bookingAmount,'en-IN',{
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })}
                        </div>
                        <h3 className="text-primary">Payment method</h3>
                        <div className="mb-3">
                            <CheckBox name="payOnline" classList="text-black" label="Pay online" control={control} />
                        </div>
                        <div className="mb-3">
                            <InputTextAreaField name="comments" label="Comments" placeholder="Add comments about your order" rows={3} control={control} />
                        </div>
                        <Row>
                            <div className="d-flex mb-3 align-items-center">
                                <div><CheckBox name="termsAndConditions" classList="text-black mb-0" label={<span className="text-dark">I have read and agree to the <Link to="/terms-and-conditions" target="blank">Terms & Conditions</Link> and <br /><Link to="/privacy-policy" target="blank">Privacy Policy</Link></span>}
                                    control={control} rule={{ required: fieldRequired, validate: (value: number) => checkRequired(value) }}
                                    errText="Check to accept terms & conditions and Privacy policy" /></div>
                                {/* <Col md={4}>
                                    <CheckBox name="notRobot" label="I'm not a robot" control={control} rule={{  }}
                                        errText="Check to confirm" />
                                </Col> */}
                            </div>
                        </Row>
                        <Button variant="primary" type="submit" disabled={submitVisible}>
                                Make payment{" "}
                            </Button> 
                         
                    </Col>
                </Row>
            </Form>
        </Container>
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>)
}


export default CarCheckout;