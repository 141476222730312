import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { ComparisonContext } from "../../../../config/context/comparisonContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import HTTP from "../../../../config/axios";
import { encryptLocalStorage } from "../../../../config/utils/storage";
import { convertStringToSlug, parsePriceToFloat } from "../../../../config/utils/helper";
import { Link } from "react-router-dom";
import { faTrashCan, faRefresh } from "@fortawesome/free-solid-svg-icons";

interface CarData {
  year: number;
  make: string;
  model: string;
  variant: string;
  image: string;
  price: number;
  isDealOfTheDay:number;
  dealPrice:number;
  // ...other properties
}

const CompareImage = ({isScrolling}:{isScrolling:boolean}) => {
  const [searchParams] = useSearchParams();
  const firstCarId = searchParams.get('firstCar');
  const secondCarId = searchParams.get('secondCar');
  const thirdCarId = searchParams.get('thirdCar');
  const fourthCarId = searchParams.get('fourthCar');

  
  const locationName = encryptLocalStorage.getItem("selectedLocation") || '';

  const { compareDetails, setCompareDetails } = useContext(ComparisonContext);

  const navigate = useNavigate();

  useEffect(() => {
    const getDetails = async (carId: string, position: string) => {
      if (carId) {
        const response = await HTTP.get(`/api/vehicle/fetch-one/${carId}`);
        const { data: { status: vehicleStatus, data: vehicleData } } = response;
        const specificationResponse = await HTTP.get(`/api/vehicle/specifications/fetch-all/compare/${carId}`);
        const { data: { status: specStatus, data: specData } } = specificationResponse;
        const featuresResponse = await HTTP.get(`/api/vehicle/features/fetch-all/compare/${carId}`);
        const { data: { status: featureStatus, data: featureData } } = featuresResponse;

        // const specCategories:any = {}

        // Object.keys(specData).forEach((spec:any) => {
        //   specCategories[specData[spec][spec.type]] = {
        //     ...(specCategories[specData[spec][spec.type]] ? specCategories[specData[spec][spec.type]] : {}),
        //     ...specData[spec]
        //   }
        // })
        //  console.log("specCategories ================", specCategories);

        setCompareDetails((prevDetails: any) => ({
          ...prevDetails,
          [position]: {
            ...(vehicleStatus ? vehicleData : {}),
            ...(specStatus ? { specs: specData } : {}),
            ...(featureStatus ? { features: featureData } : {})
          }
        }));

      }
    };

    firstCarId && getDetails(firstCarId as string, 'first');
    secondCarId && getDetails(secondCarId as string, 'second');
    thirdCarId && getDetails(thirdCarId as string, 'third');
    fourthCarId && getDetails(fourthCarId as string, 'fourth');
  }, [firstCarId, secondCarId, thirdCarId, fourthCarId, setCompareDetails]);

  const CarGridCard = ({ position }: { position: string }) => {
    const carData: CarData = compareDetails[position] || {};

    const carSlugName = carData ? `${carData.year} ${carData.make} ${carData.model} ${carData.variant}` : '';
    const slugCarName = convertStringToSlug(carSlugName);

    const vehicleIds: any = {
      first: firstCarId, second: secondCarId, third: thirdCarId, fourth: fourthCarId
    }


    const handleRemoveFromCompare = (position: string) => {
      const carId = vehicleIds[position];

      const compareString = encryptLocalStorage.getItem("compareCar");
      let compareCar: { id: string, name: string }[] = [];

      if (compareString) {
        try {
          compareCar = compareString;
        } catch (error) {
          console.error("Failed to parse compareCar from local storage:", error);
          compareCar = [];
        }
      }

      compareCar = compareCar.filter(car => car.id !== carId);

      encryptLocalStorage.setItem("compareCar", JSON.stringify(compareCar));

      setCompareDetails((prevDetails: any) => {
        const updatedDetails: any = {};
        const positions = ['first', 'second', 'third', 'fourth'];

        // Reassign remaining cars to new positions
        compareCar.forEach((car, index) => {
          if (positions[index]) {
            updatedDetails[positions[index]] = prevDetails[positions[index]] || { id: car.id };
          }
        });

        // Ensure any positions not filled are cleared
        positions.forEach((position: any) => {
          if (!updatedDetails[position]) {
            delete updatedDetails[position];
          }
        });

        return updatedDetails;
      });



      const carCount = compareCar.length;
      const navigateParams = compareCar.map((car, index) => `${index === 0 ? 'firstCar' : index === 1 ? 'secondCar' : index === 2 ? 'thirdCar' : 'fourthCar'}=${car.id}`).join('&');



      navigate(`/comparison?${navigateParams}`, { replace: true });
    };


    const formattedPrice = (price: any) => {
      let priceFormatted = "";
      let str = ""
      if (price < 1000) {
        priceFormatted = price
      } else if (price >= 1000 && price < 100000) {
        str = parsePriceToFloat(price, 1000, 2)
        priceFormatted = str + " K";
      } else if (price >= 100000 && price < 10000000) {
        str = parsePriceToFloat(price, 100000, 2)
        priceFormatted = str + " Lakhs"
      } else {
        str = parsePriceToFloat(price, 10000000, 2)
        priceFormatted = str + " Crore";
      }
      return priceFormatted;
    };



    return (
      <Col
        md={2}
        className="comparsion-img-out text-center ">
        {carData && Object.keys(carData).length > 0 ? (<>
          {/* <div style={{ position: 'sticky', top: '115px', zIndex: 1000, backgroundColor: 'white', padding: '10px', overflow: 'visible', }}> */}
            <Image
              src={`${process.env.REACT_APP_CLOUDFRONT_URL}${carData.image}`}
            width={!isScrolling?"100%":"70%"}
              style={{ cursor: "pointer" }}
              alt={carData.image}
            />
          {/* //</div> */}
            <>
            <h2 className={!isScrolling ? "is-testimonials text-dark text-truncate text-start" :"text-dark is-testimonials-mobile text-truncate text-align-center"} title={carData.model}>
                {carData.model || "-"}
              </h2>
            <h6 className={!isScrolling ? "text-primary fw-bold fs-6 mb-2 text-start" :"text-primary fw-bold fs-7 text-align-center"}>
                ₹{formattedPrice(carData.price)}</h6>
            {!isScrolling && (
              <>
                <div className="btn-group mb-3 w-100" style={{ border: "1px solid #ccc", borderRadius: "4px", overflow: "hidden" }}>
                  <Link to={`/buy-used-car`} state={{ position }} className="btn btn-light" style={{ borderRight: "1px solid #ccc", flexGrow: 1 }}>
                    <FontAwesomeIcon className="text-primary" icon={faRefresh} />
                  </Link>
                  <Button className="btn btn-light" onClick={() => handleRemoveFromCompare(position)} style={{ flexGrow: 1 }}>
                    <FontAwesomeIcon className="text-primary" icon={faTrashCan} />
                  </Button>
                </div>

                <div className="mb-3">
                  <Link to={`/buy-used-car/${slugCarName}/${vehicleIds[position]}`} className="btn w-100 btn-primary">
                    View full details
                  </Link>
                </div>
              </>
            )}
            </>
        </>
        ) : (
          <>
            <div className="add-comparison">
              <Link to={`/buy-used-car`}>
                <FontAwesomeIcon className="fs-48 text-muted" icon={faPlusCircle} />
              </Link>
            </div>
            {!isScrolling && (
              <>
                <h2 className="text-center is-testimonials text-truncate">{carData.model || "-"}</h2>
                <div className="mb-3">
                  <Link to={`/buy-used-car`} className="btn btn-primary w-100">
                    Add vehicle
                  </Link>
                </div>
              </>
            )}
          </>
        )}
      </Col>
    );
  };

  return (
    <Row>
      <Col md={2} className="comparsion-img-out text-center ">
        <div className="add-comparison">
          <Image
            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/icons/compare.svg`} />
        </div>
      </Col>
      <CarGridCard position="first" />
      <CarGridCard position="second" />
      <CarGridCard position="third" />
      <CarGridCard position="fourth" />
    </Row>
  );
};

export default CompareImage;
