import { Col, Container, Nav, NavItem, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import CompareOverview from "./Overview";
import StandardFeatures from "./StandardFeatures";
import CompareEngine from "./Engine";
import CompareTransmission from "./Transmission";
import CompareSuspension from "./Suspension";
import CompareSteering from "./Steering";
import CompareBrake from "./Brake";
import ComparePerformance from "./Performance";
import CompareFuel from "./Fuel";
import CompareImage from "./CarImage";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { ComparisonContextProvider } from "../../../../config/context/comparisonContext"
import SimpleToast from "../../../../components/common/Toast";


const ComparisonWeb = () => {
  const [isScrolling, setIsScrolling] = useState(false);

  const location = useLocation()
  let toastValues = { open: false, header: '', message: '', variant: '' }
  if(location?.state?.aboveLimit){
    toastValues = { open: true, header: 'Failed', message: 'You can compare only 4 cars at a time. Change any car in list to add new car to compare', variant: 'danger',  }
    window.history.replaceState({}, '');
  }
 
  const [compareData, setCompareData] = useState<any>({})
  const [toast, setToast] = useState(toastValues)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
         
    setIsMobile(window.innerWidth <= 565);
     
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled, for example, more than 100 pixels
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };
    // Add scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <ComparisonContextProvider>
      {/* <NavItem >
        <CompareImage />
      </NavItem> */}
      <Container>
        
        <h2 className="text-primary mb-4 mt-120 mt-lg-120 head-bold">CAR COMPARISON</h2>
       
        <Row className={`${isScrolling ? 'sticky-compare pt-2' : 'mt-5'}`}>
          <div
            className="name-price-wrapper"  
            style={{
              borderBottom: isScrolling ? "3px solid #F05336" : "none",
              margin: "auto", // Center align within column
            }}
          >
            <CompareImage isScrolling={isScrolling} />
          </div>
       
          </Row>
        <Col md={12} >
          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            <Accordion.Item eventKey="0" className="mb-2">
              <Accordion.Header className="head">
                Car overview {compareData.first}
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareOverview />  
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className="mb-2">
              <Accordion.Header className="head ">
                Standard features
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <StandardFeatures />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className="mb-2">
              <Accordion.Header className="head ">
                Engine
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareEngine />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className="mb-2">
              <Accordion.Header className="head ">
                Transmission
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareTransmission />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4" className="mb-2">
              <Accordion.Header className="head ">
                Suspension system
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareSuspension />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5" className="mb-2">
              <Accordion.Header className="head ">
                Steering
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareSteering />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6" className="mb-2">
              <Accordion.Header className="head ">
                Brake System
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareBrake />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7" className="mb-2">
              <Accordion.Header className="head ">
                Performance
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <ComparePerformance />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8" className="mb-2">
              <Accordion.Header className="head ">
                Fuel
              </Accordion.Header>
              <Accordion.Body className="border border-top-0 p-10 accordion-border-padding">
                <CompareFuel />
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
          <div className="pb-5"></div>
        </Col>
      </Container>


      <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} delay={5000} />
    </ComparisonContextProvider>
  );
};

export default ComparisonWeb;
