import { faPlusCircle, faRefresh, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { ComparisonContext } from "../../../../config/context/comparisonContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import HTTP from "../../../../config/axios";
import { encryptLocalStorage } from "../../../../config/utils/storage";
import { convertStringToSlug, parsePriceToFloat, trimContent } from "../../../../config/utils/helper";
import { Link } from "react-router-dom";

interface CarData {
  year: number;
  make: string;
  model: string;
  variant: string;
  image: string;
  price: number;
  isDealOfTheDay: number;
  dealPrice: number;
  // ...other properties
}

const CompareImage = () => {
  const [searchParams] = useSearchParams();
  const firstCarId = searchParams.get('firstCar');
  const secondCarId = searchParams.get('secondCar');

  const navigate = useNavigate();

  const locationName = encryptLocalStorage.getItem("selectedLocation") || '';

  const { compareDetails, setCompareDetails } = useContext(ComparisonContext);

  useEffect(() => {
    const getDetails = async (carId: string, position: string) => {
      if (carId) {
        const response = await HTTP.get(`/api/vehicle/fetch-one/${carId}`);
        const { data: { status: vehicleStatus, data: vehicleData } } = response;
        const specificationResponse = await HTTP.get(`/api/vehicle/specifications/fetch-all/compare/${carId}`);
        const { data: { status: specStatus, data: specData } } = specificationResponse;
        const featuresResponse = await HTTP.get(`/api/vehicle/features/fetch-all/compare/${carId}`);
        const { data: { status: featureStatus, data: featureData } } = featuresResponse;

        setCompareDetails((prevDetails: any) => ({
          ...prevDetails,
          [position]: {
            ...(vehicleStatus ? vehicleData : {}),
            ...(specStatus ? { specs: specData } : {}),
            ...(featureStatus ? { features: featureData } : {})
          }
        }));

      }
    };

    firstCarId && getDetails(firstCarId as string, 'first');
    secondCarId && getDetails(secondCarId as string, 'second');
  }, [firstCarId, secondCarId, setCompareDetails]);

  const CarGridCard = ({ position }: { position: string }) => {
    const carData: CarData = compareDetails[position] || {};

    const carSlugName = carData ? `${carData.year} ${carData.make} ${carData.model} ${carData.variant}` : '';
    const slugCarName = convertStringToSlug(carSlugName);

    const vehicleIds: any = {
      first: firstCarId, second: secondCarId
    }

    const handleRemoveFromCompare = (position: string) => {
      const carId = vehicleIds[position];

      const compareString = encryptLocalStorage.getItem("compareCar");
      let compareCar: { id: string, name: string }[] = [];

      if (compareString) {
        try {
          compareCar = compareString;
        } catch (error) {
          console.error("Failed to parse compareCar from local storage:", error);
          compareCar = [];
        }
      }

      compareCar = compareCar.filter(car => car.id !== carId);

      encryptLocalStorage.setItem("compareCar", JSON.stringify(compareCar));

      setCompareDetails((prevDetails: any) => {
        const updatedDetails: any = {};
        const positions = ['first', 'second'];

        // Reassign remaining cars to new positions
        compareCar.forEach((car, index) => {
          if (positions[index]) {
            updatedDetails[positions[index]] = prevDetails[positions[index]] || { id: car.id };
          }
        });

        // Ensure any positions not filled are cleared
        positions.forEach((position: any) => {
          if (!updatedDetails[position]) {
            delete updatedDetails[position];
          }
        });

        return updatedDetails;
      });

      const carCount = compareCar.length;
      const navigateParams = compareCar.map((car, index) => `${index === 0 ? 'firstCar' : 'secondCar'}=${car.id}`).join('&');



      navigate(`/comparison?${navigateParams}`, { replace: true });
    };


    const formattedPrice = (price: any) => {
      let priceFormatted = "";
      let str = ""
      if (price < 1000) {
        priceFormatted = price
      } else if (price >= 1000 && price < 100000) {
        str = parsePriceToFloat(price, 1000, 2)
        priceFormatted = str + " K";
      } else if (price >= 100000 && price < 10000000) {
        str = parsePriceToFloat(price, 100000, 2)
        priceFormatted = str + " Lakhs"
      } else {
        str = parsePriceToFloat(price, 10000000, 2)
        priceFormatted = str + " Crore";
      }
      return priceFormatted;
    };




    return (
      <Col className=" text-center " xs={5} sm={5}>
        {carData && Object.keys(carData).length > 0 ? (<>
          <Image
            src={`${process.env.REACT_APP_CLOUDFRONT_URL}${carData.image}`}
            width={"100%"}
            style={{ cursor: "pointer" }}
            alt={carData.image}
          />
          <h4 className="text-dark  text-start is-testimonials text-truncate fs-18" title={carData?.model || ''}>{trimContent(carData?.model, 10) || "-"}</h4>
          <h6 className="text-primary fw-bold fs-6 mb-2 text-start">
            ₹{formattedPrice(carData.price)}</h6>
          {/* <div className="d-flex justify-content-between mb-3">
            <div>
              <Link to={`/buy-used-car`} state={{ position }} className="btn btn-outline-primary w-100">
                <FontAwesomeIcon icon={faRefresh} />
              </Link>
            </div>
            <div>
              <Button className="w-100" onClick={() => handleRemoveFromCompare(position)}>
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
            </div>
          </div> */}
          <div className="btn-group mb-3 w-100" style={{ border: "1px solid #ccc", borderRadius: "4px", overflow: "hidden" }}>
            <Link to={`/buy-used-car`} state={{ position }} className="btn btn-light" style={{ borderRight: "1px solid #ccc", flexGrow: 1 }}>
              <FontAwesomeIcon className="text-primary" icon={faRefresh} />
            </Link>
            <Button className="btn btn-light" onClick={() => handleRemoveFromCompare(position)} style={{ flexGrow: 1 }}>
              <FontAwesomeIcon className="text-primary" icon={faTrashCan} />
            </Button>
          </div>
          <div className=" mb-3">
            <Link to={`/buy-used-car/${slugCarName}/${vehicleIds[position]}`} className="btn btn-primary w-100">
              Full&nbsp;details
            </Link>
          </div>

        </>) : (<><div className="add-comparison">
          <Link to={`/buy-used-car`} >
            <FontAwesomeIcon className="fs-48 text-muted" icon={faPlusCircle} />
          </Link>
        </div>

          <h2 className="text-center is-testimonials text-truncate" title={carData?.model || ''}>{carData?.model || "-"}</h2>
          <div className=" mb-3">
            <Link to={`/buy-used-car`} className="btn btn-primary w-100">
              Add vehicle
            </Link>
          </div>
        </>)}


      </Col>
    );
  };

  return (
    <Row>
      <Col className="d-flex align-items-center text-center " xs={2} sm={2}>
        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/icons/compare.svg`} />
        {/* <div className="add-comparison">
        
        </div> */}
      </Col>
      <CarGridCard position="first" />
      <CarGridCard position="second" />
    </Row>
  );
};

export default CompareImage;
