import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { PaginationChangeRowsPerPage } from 'react-data-table-component/dist/DataTable/types';

interface ReportDataProps {
    reportData: any
    handlePageChange: PaginationChangeRowsPerPage | undefined
    handlePerRowsChange: PaginationChangeRowsPerPage | undefined
}

const VehicleSoldReport: React.FC<ReportDataProps> = ({ reportData, handlePageChange, handlePerRowsChange }): any => {




    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: <div>Make</div>,
            wrap: true,
            sortable: false,
            keyName: 'makeName',
            selector: (row: { makeName: string }) => `${row.makeName}`,
        },
        {
            name: <div>Model</div>,
            wrap: true,
            sortable: false,
            keyName: 'modelName', // Corrected from 'model' to 'modelName'
            selector: (row: { modelName: string }) => `${row.modelName}`, // Corrected from 'model' to 'modelName'
        },
        {
            name: <div>Variant</div>,
            wrap: true,
            sortable: false,
            keyName: 'variantName',
            selector: (row: { variantName: string }) => `${row.variantName}`,
        },
        {
            name: <div>First name</div>,
            wrap: true,
            sortable: false,
            keyName: 'firstName',
            selector: (row: { firstName: string }) => `${row.firstName}`,

        },
        {
            name: <div>Last name</div>,
            wrap: true,
            sortable: false,
            keyName: 'lastName',
            selector: (row: { lastName: string }) => `${row.lastName}`,

        },
        {
            name: <div>Price</div>, // Added div wrapper for consistency
            keyName: 'price',
            selector: (row: { price: number; }) => row.price,
            sortable: false,
        },
        {
            name: <div>KM Driven</div>, // Added to display kmDriven
            keyName: 'kmDriven',
            selector: (row: { kmDriven: number; }) => row.kmDriven,
            sortable: false,
        },
        {
            name: <div>Sold Date</div>, // Added to display manufactureDate
            keyName: 'vehicleSoldDate',
            selector: (row: { vehicleSoldDate: string; }) => row.vehicleSoldDate,
            sortable: false,
        },
        {
            name: <div>Registration</div>, // Added to display registration number
            keyName: 'registration',
            selector: (row: { registration: string; }) => row.registration,
            sortable: false,
        },
    ];


    return (<>
        <br></br>
        <DataTable
            columns={columns}
            data={reportData.vehicleSoldData.rows}
            striped={true}
            pagination
            paginationServer
            paginationTotalRows={reportData.count}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

    </>)

}
export default VehicleSoldReport