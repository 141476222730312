import { Container } from 'react-bootstrap'
import { useEffect, useState, useCallback } from "react";
import HTTP from '../../../config/axios';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import VehicleBasicDetailForm from "./vehicleBasicDetails"
import FeatureForm from "./vehicleFeaturesForm"
import ImageForm from "./vehicleImageAddEdit"
import SpecificationForm from './vehicleSpecificationForm';
import { useSearchParams } from 'react-router-dom';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from "../../common/Spinner";
import DentMap from './vehicleDentMap';
import { getFormatyyyymmdd } from '../../../config/utils/dateFunctions';
import InspectionForm from "./vehicleInspectionForm"
import VehicleUserInfo from './vehicleUserInfo';



const AddEditVehicle: React.FC<any> = () => {

  const [activeTab, setActiveTab] = useState("basic");
  const [vehicleDetails, setVehicleDetails] = useState<any>({});
  const [featureForm, setFeatureForm] = useState(false);
  const [specificationForm, setSpecificationForm] = useState(false)
  const [imageForm, setImageForm] = useState(false)
  const [dentMapForm, setDentMapForm] = useState(false)
  const [vehicleId, setVehicleId] = useState('');
  const [vehicleUuid, setvehicleUuid] = useState('')
  const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
  const [isLoading, setIsLoading] = useState(false);
  const [inspectionForm, setInspectionForm] = useState(false)
  const [userInfoForm, setuserInfoForm] = useState(false)
  const [userProfile, setUserProfile] = useState<any>({ firstName: "", lastName: "",  phone: "", email: ""});
  const [userAddress, setUserAdress] = useState<any>({ address: "", landmark: "" });


  const switchTabs = (tabKey: string | null) => {
    if (tabKey === 'specification' && specificationForm) {
      setActiveTab('specification')
    } else if (tabKey === 'feature' && featureForm) {
      setActiveTab('feature')
    } else if (tabKey === 'images' && imageForm) {
      setActiveTab('images')
    } else if (tabKey === 'dentMap' && dentMapForm) {
      setActiveTab('dentMap')
    } else if (tabKey === 'inspection' && inspectionForm) {
      setActiveTab('inspection')
    } 
    else if (tabKey === 'userInfoForm' && userInfoForm) {
      setActiveTab('userInfoForm')
    }else {
      setActiveTab('basic')
    }
  }

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('vehicle')) {
      const queryParams = { vehicleId: searchParams.get('vehicle') };
      const getCarDetails = async () => {
        const response = await HTTP.get(`/api/admin/vehicle/vehicleDetails`, { params: queryParams });
        const { data: { status, data, msg, error } } = response
        if (status == 1) {
          data.vehicleDetails.lifestyleIds = data.lifestyle
          data.vehicleDetails.dealLastDate = getFormatyyyymmdd(data.vehicleDetails.dealLastDate)
          setVehicleDetails(data.vehicleDetails)
          setVehicleId(data.vehicleDetails.id)
          setFeatureForm(true)
          setSpecificationForm(true)
          setImageForm(true)
          setDentMapForm(true)
          setInspectionForm(true)
          setuserInfoForm(true)
          setvehicleUuid(data.vehicleDetails.uuid)
          const userData: any = {
            firstName: data.vehicleDetails.firstName,
            lastName: data.vehicleDetails.lastName,
            phone: data.vehicleDetails.phone,
            email: data.vehicleDetails.email,
         }

          const sellerData = data.vehicleMetaData

          if(sellerData) {
              setUserAdress({
                address: sellerData.address,
                landmark: sellerData.landmark,
              });
          }

          setUserProfile(userData);

        } else {
          console.log('fetch API failed')
        }
      }
      // const getLifestyleDetails = async () => {
      //   const lifestyleResponse = await HTTP.get(`/api/admin/vehicle/lifestyle/fetch-vehicle-lifestyle`, { params: vehicleId });
      //   const { data: { status, data, msg, error } } = lifestyleResponse
      //   if (status == 1) {
      //     setLifestyleDetails(data)
      //   } else {
      //     console.log('fetch API failed')
      //   }
      // }
      // getLifestyleDetails()
      getCarDetails()
    }

  }, [])

  return (<>
    <Container fluid>
      <Tabs
        activeKey={activeTab}
        onSelect={(key: string | null) => { switchTabs(key) }}
        transition={false}
        id="noanim-tab-example"
        className="mt-5 mb-3"
      >
        <Tab eventKey="basic" title="Basic Details">
          {activeTab == "basic" && <VehicleBasicDetailForm currentVehicleDetails={vehicleDetails}
            setVehicleDetails={setVehicleDetails}
            vehicleId={vehicleId}
            setActiveTab={setActiveTab}
            setFeatureForm={setFeatureForm}
            setSpecificationForm={setSpecificationForm}
            setImageForm={setImageForm}
            setDentMapForm={setDentMapForm}
            setVehicleId={setVehicleId}
            vehicleUuid={vehicleUuid}
            setvehicleUuid={setvehicleUuid}
            setToast={setToast}
            setIsLoading={setIsLoading}
            initialDod={vehicleDetails?.dealLastDate}
            setInspectionForm={setInspectionForm}
            setuserInfoForm={setuserInfoForm}
             />}
        </Tab>
        <Tab eventKey="feature" title="Features" disabled={!featureForm}>
          {activeTab == "feature" && <FeatureForm vehicleId={vehicleId} setToast={setToast} setIsLoading={setIsLoading} setActiveTab={setActiveTab} />}
        </Tab>
        <Tab eventKey="specification" title="Specification" disabled={!specificationForm}>
          {activeTab == "specification" && <SpecificationForm vehicleId={vehicleId} setToast={setToast} setIsLoading={setIsLoading} setActiveTab={setActiveTab} />}
        </Tab>
        <Tab eventKey="images" title="Images" disabled={!imageForm}>
          {activeTab == "images" && <ImageForm vehicleId={vehicleId} vehicleUuid={vehicleUuid} setToast={setToast} setIsLoading={setIsLoading} />}
        </Tab>
        <Tab eventKey="dentMap" title="Dent Map" disabled={!dentMapForm}>
          {activeTab == "dentMap" && <DentMap vehicleId={vehicleId} vehicleUuid={vehicleUuid} setToast={setToast} setIsLoading={setIsLoading} />}
        </Tab>
        <Tab eventKey="inspection" title="Inspection Report" disabled={!inspectionForm}>
          {activeTab == "inspection" && <InspectionForm vehicleId={vehicleId} setToast={setToast} setIsLoading={setIsLoading} setActiveTab={setActiveTab} />}
        </Tab>
        <Tab eventKey="userInfoForm" title="User Information" disabled={!userInfoForm}>
          {activeTab == "userInfoForm" && <VehicleUserInfo userProfile={userProfile} userAddress={userAddress} /> }
        </Tab>
      </Tabs>

    </Container>
    <SimpleToast show={toast.open} header={toast.header}
      message={toast.message} variant={toast.variant}
      onClose={() => setToast({ ...toast, open: false })} />
    <SimpleSpinner show={isLoading} />

  </>)
}

export default AddEditVehicle