// This file contains different form input rules

export const fieldRequired = (fieldName: string) => {
    return {
        value: true,
        message: `${fieldName || 'Field'} is required`,
    }
};

export const lengthBetween = (value: string, minValue: number, maxValue: number) => {
    if (value.length < minValue || value.length > maxValue) {
        return `Character length must be between ${minValue} and ${maxValue}`;
    }

    return true
}

export const checkRequired = (value: number) => {
    if (value != 1) {
        return `Field is required`;
    }

    return true
}

export const maxLength = (count: number) => {
    return {
        value: count,
        message: `Character length must be less than or equal to ${count}`,
    }
};
export const minLength = (count: number) => {
    return {
        value: count,
        message: `Character length must be greater than or equal to ${count}`,
    }
};

export const max = (count: number) => {
    const digitLabel = count > 1 ? 'digits' : 'digit'
    return {
        value: count,
        message: `Number must be less than or equal to ${count.toString().length} ${digitLabel}`,
    }
};

export const min = (count: number) => {
    const digitLabel = count > 1 ? 'digits' : 'digit'
    return {
        value: count,
        message: `Number must be greater than or equal to ${count.toString().length} ${digitLabel}`,
    }
};

export const validateRoundedNumber = (value:number|string) => {
    if(value){
        const parsedNumber = parseFloat(value as string)
        if (!Number.isInteger(parsedNumber)) {
            return 'Value must be a rounded number';
        }
    }
    return true;
  };

export const validateOnlySpaces = (value: string) => {
    const isOnlySpaces = /^\s*$/.test(value);
    if (isOnlySpaces && value) {
        return 'Value must not contain only spaces';
    }
    return true;
};

// valiation for number field that required a given number of digits
export const equalTo = (value: number, requiredDigits: number) => {
    const digitCount = value.toString().length
    if (digitCount !== requiredDigits) {
        return `Number must have ${requiredDigits} digits`;
    }

    return true
}

// valiation for number field that required a given number of digits
export const equalCharCheck = (value: string, requiredLength: number) => {
    const valueLength = value.length
    if (valueLength !== requiredLength) {
        return `Character length must be ${requiredLength}`;
    }

    return true
}

export const emailPattern = {
    // value: new RegExp('^(?!\\.)[a-zA-Z0-9._-]+(?<!\\.)@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
    value: new RegExp('^(?!\\.)^(?!.*\\.\\.)[a-zA-Z0-9._-]+(?<!\\.|\\-|\\.)@(?![.-])[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),

    message: 'Enter a valid email address',
};
export const percentPattern = {
    value: new RegExp('^[1-9][0-9]?$|^100$'),
    message: 'Enter a valid percentage',
};

export const phoneNumberPattern = {
    value: new RegExp('^[0-9]{10}$'),
    message: 'Mobile number must be 10 digits'
}

export const pinCodePattern = {
    value: new RegExp('^[0-9]{6}$'),
    message: 'Pin code must be 6 digits'
}

export const panCardEqualTo = (value: string, requiredDigits: number) => {
    const digitCount = value.length
    if (digitCount !== requiredDigits) {
        return `Character length must be equal to ${requiredDigits}`;
    }

    return true
}

export const checkPassword = (value: string, password: string) => {
    if (value !== password) {
        return `Passwords do not match`;
    }
    return true
}

export const validateDob = (value: string) => {
    const selectedDate = new Date(value);
    const currentDate = new Date();
    if (currentDate < selectedDate) {
        return `DOB should not be a future date`
    }
    return true
}

export const validatePAN = {
    value: new RegExp('^[A-Z]{5}[0-9]{4}[A-Z]$'),
    message: 'Enter valid PAN number'
}

export const validateDecimalPlaces = (value: string) => {
    if (!/^\d+(\.\d{1,2})?$/.test(value)) {
      return 'Up to two decimal places are allowed.';
    }
    return true;
};