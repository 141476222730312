import React, { useEffect } from "react";
import InputTextField from "../../form/InputTextField";
import { Col, Row} from "react-bootstrap";
import { fieldRequired, phoneNumberPattern } from "../../../config/validations/rules";
import { useForm } from "react-hook-form";

// Reuse the UserProfile and AuthUser interfaces


interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface userAddress {
  address: string;
  landmark: string;
}


// Define the props type for the component
interface VehicleUserInfoProps {
  userProfile: UserProfile;
  userAddress: userAddress
}

const VehicleUserInfo: React.FC<VehicleUserInfoProps> = ({ userProfile, userAddress }) => {
  const { register, handleSubmit, formState: { errors }, setError, setValue, getValues, clearErrors, control, reset, watch } = useForm<Record<string, any>>({ });

  useEffect(() => {
    reset({
      ...userProfile,
      address: userAddress.address,
      landmark: userAddress.landmark,
    });
  }, [userProfile, userAddress]);

  return (
    <>
      <h1>Seller Info</h1>
    <Row className="mt-4">
        <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="firstName"
              label="First Name"
              placeholder="First name"
              disabled={true}
              clearErrors={clearErrors}
              control={control}
            />
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="lastName"
              label="Last Name"
              placeholder="Last name"
              clearErrors={clearErrors}
              disabled={true}
              control={control}
            />
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="phone"
              label="Phone number"
              placeholder="Phone number"
              clearErrors={clearErrors}
              type="number"
              control={control}
              disabled={true} // Disables the phone input field
            />
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="email"
              label="Email address"
              placeholder="Enter your email"
              clearErrors={clearErrors}
              disabled={true}
              control={control}
            />
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="address"
              label="address"
              placeholder="address "
              clearErrors={clearErrors}
              type="string"
              control={control}
              disabled={true} // Disables the phone input field
            />
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="landmark"
              label="landmark"
              placeholder="landmark"
              clearErrors={clearErrors}
              type="string"
              control={control}
              disabled={true} // Disables the phone input field
            />
          </div>
        </Col>
    </Row>
    </>
  );
};

export default VehicleUserInfo;
